var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [
            _vm._v("Standaardtekst toevoegen")
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? [
                    _c("loader-card", {
                      attrs: { type: "spinner--center", flat: true }
                    })
                  ]
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Titel" },
                              model: {
                                value: _vm.defaultAnswerData.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.defaultAnswerData, "title", $$v)
                                },
                                expression: "defaultAnswerData.title"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Question Key" },
                              model: {
                                value: _vm.defaultAnswerData.question,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.defaultAnswerData,
                                    "question",
                                    $$v
                                  )
                                },
                                expression: "defaultAnswerData.question"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "block tw-text-xl tw-text-gray-700 tw-mb-8"
                              },
                              [_vm._v("Instructies")]
                            ),
                            _c("QuillEditor", {
                              attrs: { label: "TEST" },
                              model: {
                                value: _vm.defaultAnswerData.instruction,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.defaultAnswerData,
                                    "instruction",
                                    $$v
                                  )
                                },
                                expression: "defaultAnswerData.instruction"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm.isRichText
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "block tw-text-xl tw-text-gray-700 tw-mb-8"
                                    },
                                    [_vm._v("Tekst")]
                                  ),
                                  _c("QuillEditor", {
                                    model: {
                                      value: _vm.defaultAnswerData.text,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.defaultAnswerData,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression: "defaultAnswerData.text"
                                    }
                                  })
                                ]
                              : _vm._e(),
                            !_vm.isRichText
                              ? [
                                  _c("v-textarea", {
                                    attrs: { label: "Tekst" },
                                    model: {
                                      value: _vm.defaultAnswerData.text,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.defaultAnswerData,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression: "defaultAnswerData.text"
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _c("v-checkbox", {
                              staticClass: "tw-mt-8",
                              attrs: {
                                color: "primary",
                                label: "Rich text editor",
                                "hide-details": ""
                              },
                              on: { change: _vm.onChangeRichText },
                              model: {
                                value: _vm.isRichText,
                                callback: function($$v) {
                                  _vm.isRichText = $$v
                                },
                                expression: "isRichText"
                              }
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              disabled: _vm.isDisabled,
                              color: "primary",
                              small: "true"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit($event)
                              }
                            }
                          },
                          [_vm._v("Toevoegen")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }