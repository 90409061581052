import { DefaultAnswer } from '@/models/DefaultAnswer';
import { AxiosError, AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { Options } from '@/components/mi-dialog/MiDialog';
import ErrorHandler from '@/support/ErrorHandler';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { formatDate } from '@/support/String';
import CreateDefaultAnswerDialog from './CreateDefaultAnswerDialog/CreateDefaultAnswerDialog.vue';

@Component<DefaultAnswers>({
  components: {
    CreateDefaultAnswerDialog,
  },
})
export default class DefaultAnswers extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  public $pageTitle = 'Standaardteksten';

  protected isLoading = false;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected isCreatingDefaultAnswer = false;

  protected selectedAnswer: DefaultAnswer | null = null;

  protected tableFilters = {};
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  protected created(): void {
    this.reRoute();
  }

  protected async mounted(): Promise<void> {
    this.reRoute();
  }
  // #endregion

  // #region Class methods

  protected refreshDatatable(): void {
    if (this.$refs.defaultAnswersDatatable) {
      (this.$refs.defaultAnswersDatatable as DataTable).refresh();
    }
  }

  protected reRoute(): void {
    if (! this.$store.state.Auth.isAtabix) {
      this.$router.push('/');
    }
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async deleteDefaultAnswer(): Promise<void> {
    if (! this.selectedAnswer || ! this.selectedAnswer.id) {
      return;
    }

    await this.selectedAnswer
      .delete()
      .then(() => {
        this.refreshDatatable();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get tableOptions(): Object {
    return {
      model: new DefaultAnswer().limit(25).filter(this.datatableFilters).sort('sort_order', 'ASC'),
      name: {
        singular: 'Standaardtekst',
        plural: 'Standaardteksten',
      },
      headers: [
        {
          text: 'Onderwerp',
          value: 'title',
          width: '30%',
        },
        {
          text: 'inhoud',
          value: 'text',
        },
        {
          text: 'Toegevoegd op',
          value: 'updated_at',
          transform: (updated_at: any) => formatDate(updated_at, 'dd-LL-yyyy HH:mm'),
          sortable: {
            key: 'updated_at',
            order: 'DESC',
          },
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: (dataTable: DataTable, answer: DefaultAnswer) => {
            const routeData = this.$router.resolve(`/default-answers/${answer.id}`);
            window.open(routeData.href, '_blank');
          },
          tooltip: 'Aanpassen',
        },
        {
          type: 'view',
          label: 'view',
          icon: 'delete',
          action: (datatable: DataTable, answer: DefaultAnswer) => {
            this.selectedAnswer = answer;
            this.$store.dispatch('openDialog', this.dialogOptionsDelete);
          },
        },
      ],
    };
  }

  protected get dialogOptionsDelete(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze standaard tekst wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            this.deleteDefaultAnswer();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.$emit('closeDialog');
            this.selectedAnswer = null;
          },
        },
      },
    };
  }

  protected get datatableFilters(): any {
    return this.tableFilters;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
