import { CreateDefaultAnswerPayload, DefaultAnswer } from '@/models/DefaultAnswer';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Question } from '@/models/Question';
import QuillEditor from '@/components/quill-editor/QuillEditor.vue';

@Component<CreateDefaultAnswerDialog>({
  components: {
    DefaultDialog,
    QuillEditor,
  },
})
export default class CreateDefaultAnswerDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop()
  protected reportType!: string;
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  protected defaultAnswerData: CreateDefaultAnswerPayload = {};

  protected selectedQuestion: Question | null = null;

  protected isRichText = false;

  protected stringInputText = '';

  protected isLoading = false;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  // #endregion

  // #region Class methods
  protected close(): void {
    this.$emit('input', false);
  }

  protected onChangeRichText(richText: boolean): void {
    if (richText === false) {
      const div = document.createElement('div');
      div.innerHTML = this.defaultAnswerData.text || '';
      const text = div.textContent || div.innerText || '';
      this.defaultAnswerData.text = text;
    }
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async submit(): Promise<void> {
    if (! this.defaultAnswerData) { return; }
    this.isLoading = true;
    await new DefaultAnswer()
      .create(this.createPayload)
      .then(() => {
        this.$emit('reload');
        this.close();
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get isDisabled() {
    return ! this.defaultAnswerData || ! this.defaultAnswerData.question || ! this.defaultAnswerData.title || ! this.defaultAnswerData.instruction || ! this.defaultAnswerData.text;
  }

  protected get createPayload(): CreateDefaultAnswerPayload {
    return {
      title: this.defaultAnswerData?.title || '',
      instruction: this.defaultAnswerData?.instruction || '',
      text: this.defaultAnswerData?.text || '',
      is_rich_text: this.isRichText,
      question: this.defaultAnswerData?.question || '',
    };
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
